import React from 'react'
import LandImage from '../New assets/Flyers/REAL ESTATE INNER PAGE.jpg'

function RealEstate() {
  return (
    <div className='pt-[120px] pb-20'>
      <div className='container text-[18px]'>
        <h1 className='text-center mb-10'>Real Estate</h1>
        <p className='mb-10'>
          Mercyland Estates Limited was founded to be a company for everyone who dares to become a property owner in any part of Nigeria and indeed the world. Our organization brings 15+ years of experience committed to providing a way for those who want to become land/property owners without hassles. We have made over 1,500 people proud land and property owners!<br/><br/>
          Mercyland Estates Limited is in business purposely to play a role in making it convenient for as many as possible to have easy access to secured, Government approved, relatively cheap plots/acres of land. When it comes to Mercyland Estates Limited services, our integrity, transparency, attention to detail, and professional approach set us apart. We walk with you every step along your journey to become a land/property owner. Mercyland Estates Limited makes buying and selling of real estate as cost effective as possible and maintaining the highest level of service delivery.
        </p>
      </div>
      
      
      <div>
        <h2 className='text-center'>Our Estates</h2>
        <div className='container flex flex-col lg:flex-row gap-4 py-20'>
          <div>
            <h4>MERCYLAND IDERA ESTATE (SAVE-TO-OWN SCHEME)</h4>
            <p className='text-[16px]'>“Build your own dreams, or someone else will hire you to build theirs”
              It is of no doubt, we all desire to live a beautiful life, in a serene environment.🏡
              Mercyland Idera Estate, located at SHOLO, a residential & commercial quarters, via Ashipa town, Siun, around Sagamu Interchange, Ogun State.
            </p>
            <p className='text-[18px]'>
              <h5>LOCATION</h5> 
              <p>AGORO via Ashipa Town, Siun, off Abeokuta Expressway, Sagamu Interchange. 
                About 25 minutes’ drive from RCCG Redemption Camp, Mowe, Ogun State.</p> 
            </p>
            <h5>TITLE</h5>
            <p>
              Perimeter Survey | Deed of Assignment | Ogun State Land Charting Information Certificate (LCIC) 
            </p>
            <h5>LAND SIZE</h5>
            <p>One Acre (6 Plots)  |   Half Acre (3 Plots)</p>
            <h4 className='text-center bg-green-500 rounded-3xl text-white w-[200px] mx-auto mt-3 py-2'>OUTRIGHT PAYMENT</h4>
            <p className='font-bold text-[25px]'>N2,500,000 (1 Acre), N1,250,000 (½ Acre)</p>
          </div>
          <div className='shadow-1'>
          <img className='rounded-3xl w-[100%]' src={LandImage} alt='' />
          </div>
        </div>

          <div className='bg-slate-100 text-center py-20'>
            <h1 className='text-center font-bold mb-4'>(SAVE-TO-OWN SCHEME) INSTALMENT PAYMENT PLAN</h1>
            <div className='container bg-green-500 p-2 rounded-3xl text-white text-[25px] font-bold py-5 w-auto'>
              <p className=''>Pay N50,000 for 54 months for 1 Acre (6plots).</p>
              <p className=''>Pay N25,000 for 54 months for ½ Acre (3plots).</p>
              {/* <p className=''>Monthly payment for 24 months -₦65,000.</p>
              <p className=''>Monthly payment for 54 months -₦30,000.</p>
              <p className=''>Outright payment ₦500,000 per plot</p>
              <p className=''>Monthly payment for 8 months -₦38,000 (₦150,000 deposit required).</p>
              <p className=''>Monthly payment for 45 months -₦10,000 DOWN PAYMENT.</p> */}
            </div>
          </div>
      </div>
      <div className='text-center py-20'>
        <h1 className='text-center font-bold mb-4'>Terms & Conditions apply</h1>
        <div className='container bg-[#af6ec2] p-2 rounded-3xl text-white text-[25px] font-bold py-5 w-auto'>
          <h3 className='text-center'>OTHER FEES</h3>
          <p>
            Deed of Assignment- N250,000 per acre & #125,000 per ½ acre<br /><br />
            Survey Fees- N200,000 per acre & per ½ acre<br /><br />
            Commercial Survey cost -#250,000 per acre<br /><br />
            Development Fee - #250,000 per acre/ 125,000 per ½ acre
          </p>
        </div>
      </div>

      <div>
        <div className='bg-slate-100'>
          <div className='container py-20 flex flex-col lg:flex-row gap-5 justify-center'>
            <div className='bg-white shadow-1 p-5'>
              <h1 className='font-bold mb-4'>ESTATE FEATURES</h1>
              <div className='text-[20px]'>
                <p>Free from Government Acquisition</p>
                <p>Prime & Tabletop dryland</p> 
                <p>Easy road network</p>
                <p>About 20 minutes’ drive from RCCG Redemption Camp</p>
                <p>Land good for farming</p>
              </div>
            </div>

            <div className=''>
              <h1 className='text-center font-bold mb-4'>LANDMARKS</h1>
              <div className='container bg-green-500 text-center px-5 rounded-3xl text-white text-[25px] font-bold py-5 '>
                <p>📍Nestle Water factory</p>
                <p>📍Golden Heart College</p>
                <p>📍Olam Agric</p>
                <p>📍Emzor Company</p>
                <p>📍International Brewery and many more!</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className='text-center py-20 font-bold'>
            <h4>SIZE</h4>
            <p className='text-[25px] mb-5'>One Acre (6 plots) is approximately 4000 square.meters</p>

            <h4>DOCUMENTS RECEIVED AFTER PAYMENT</h4>
            <p className='text-[25px]'>Receipts of Purchase| Deed of Assignment |Survey Plan</p>
          </div>
        </div>

        <div className='bg-slate-100'>
          <div className='container py-20 flex flex-col lg:flex-row gap-5 justify-center'>
            <div className='container bg-green-500 text-center px-5 rounded-3xl text-white text-[25px] font-bold py-5 '>
              <h1 className='text-center font-bold mb-4'>Bank Details</h1>
              <p>Mercyland Estates Ltd <br/>
                1130071953 <br/>
                Zenith Bank
              </p>
            </div>

            <div className='bg-white shadow-1 p-5'>
              <h3 className='font-bold mb-4'>INSPECTION</h3>
              <p className='text-[20px]'>FREE inspection to the site is every week. It is important that 
                you call the office phone lines to book ahead. By calling the office lines for proper 
                booking. <br/><br/>
              </p>
              <p>Don’t drag | Don’t stall | Don’t wait<br/><br/></p>
            </div>
          </div>

          <div className='py-10'>
            <h3 className='text-center py-8'>Contact us today to enjoy this amazing product.</h3>
            <div className='container flex flex-col lg:flex-row gap-5 justify-center'>
              <div className='container bg-green-500 text-center px-5 rounded-3xl text-white text-[25px] font-bold py-5 '>
                <h1 className='text-center font-bold mb-4'>Visit Us</h1>
                <p>Head Office: 4th floor, 89 Allen Avenue, Opp. GTBank, Ikeja, Lagos State.
                  Branch Office: Macflory’s Place, Canaan Land Market, Redemption City, Mowe, Ogun State.

                </p>
              </div>

              <div className='bg-white shadow-1 p-5'>
                <h3 className='font-bold mb-4'>Call/ WhatsApp for more Information </h3>
                <p className='text-[20px]'>08038949316, 09169880123<br/>
                  09169880124, 09169880125
                </p>
              </div>
            </div>
          </div>
          

          <div className='text-center font-bold text-[25px] py-10'>
            <p>Don't be left out! Seize the opportunity to own a land today!!!<br/>
              Call/WhatsApp: 08038949316, 09169880123, 09169880124, 09169880125
            </p>
          </div>
        </div>

      </div>

      <div className='container pt-20'>
        <h2>MERCYLAND VICTORY ESTATE</h2>
        <p>
          It's a NEW ERA! Welcome to MERCYLAND VICTORY ESTATE, this exceptional estate offers fully 
          residential and commercial estate of your dream. Your positive action combined with positive 
          thinking results in success” Invest smartly in Mercyland Victory Estate and get more value for 
          your investments.<br/><br/>

          {/* Land Texture/Status: 100% Dry<br/><br/> */}

          Location: APINDAN via Ashipa town, Siun, close to Sagamu-Interchange, Ogun State.
        </p>

          <h4>Estate Features</h4>
          <ul>
              <ol>• Gated community</ol>

              <ol>• Paved road network</ol>

              <ol>• Green areas/landscaping</ol>

              <ol>• Electrification</ol>

              <ol>• Recreational centres/sporting arena</ol>

              <ol>• Free from land grabbers</ol>

              <ol>• Certificate of Occupancy</ol>

              <ol>• Instant Allocation</ol>
              
              </ul>

              <h4>Neighbourhood</h4>
              <ul>
                <ol>• Mayfair Gardens</ol>

                <ol>• Lakowe Lakes Golf and Country Estate</ol>

                <ol>• Lagos Business School</ol>

                <ol>• Novare Mall(ShopRite)</ol>

                <ol>• Coscharis Motors</ol>

                <ol>• Green Springs School,Awoyaya</ol>

                <ol>• AutoNation</ol>
              </ul>
          
              <div className='flex flex-col lg:flex-row gap-3 lg:justify-center'>
                <div className='shadow-1 px-3 py-2'>
                    <h4>Size/Plot</h4>
                    <p className='bg-[#af6ec2] py-1 px-3 text-white rounded-full text-[18px]'>500 sq.m</p>
                </div>
                <div className='shadow-1 px-3 py-2'>
                    <h4>Title</h4>
                    <p className='bg-[#af6ec2] py-1 px-3 text-white rounded-full text-[18px]'>Survey & Deed of Assignment</p>
                </div>
                <div className='shadow-1 px-3 py-2'>
                    <h4>Actual Price</h4>
                    <p className='bg-[#af6ec2] py-1 px-3 text-white rounded-full text-[18px]'>N750,000 per plot</p>
                </div>
                {/* <div className='shadow-1 px-3 py-2'>
                    <h4>Current Payment Plan</h4>
                    <p className='bg-[#af6ec2] py-1 px-3 text-white rounded-full text-[18px]'>Outright payment (₦16M)</p>
                </div> */}
                <div className='shadow-1 px-3 py-2'>
                    <p className='bg-[#af6ec2] py-1 px-3 text-white rounded-full text-[18px]'>No Installment</p>
                </div>
            </div>

            <div className='flex flex-col lg:flex-row'>
              <div className='py-4 px-3'>
                  <h4>INSTALMENT PAYMENT PLAN</h4>
                  <ul>
                      <li>
                          &#8226; Plan A ➖ 100,000 for 8 months.
                      </li>
                      <li>
                          &#8226; Plan B ➖ 50,000 for 16 months.
                      </li>
                      {/* <li>
                          &#8226; Contract of Sale and Survey Plan
                      </li> */}
                  </ul>
              </div>

              <div className='py-4 px-3'>
                  <h4>OTHER FEES</h4>
                  <ul>
                      <li>
                          &#8226; Deed of Assignment- #75,000 per plot
                      </li>
                      <li>
                          &#8226; Survey Fees- #200,000 per plot 
                      </li>
                      <li>
                          &#8226; Commercial Survey cost -#250,000 per plot
                      </li>
                      <li>
                          &#8226; Development Fee - #50,000
                      </li>
                  </ul>
              </div>
            </div>
            
            <div className='py-4'>
                <h4>Documents Received after payment</h4>
                <ul>
                    <li>
                      Receipts of Purchase | Deed of Assignment |Survey
                    </li>
                </ul>
            </div>

            <div className='flex flex-col lg:flex-row'>
              <div className='py-4 px-3'>
                  <h4>ESTATES FEATURES </h4>
                  <ul>
                      <li>
                          &#8226; Residential estate and Commercial Hubs
                      </li>
                      <li>
                          &#8226; Free from Government Acquisition
                      </li>
                      <li>
                          &#8226; About 20 minutes’ drive from RCCG Redemption Camp
                      </li>
                      <li>
                          &#8226; Good motorable road network
                      </li>
                      <li>
                          &#8226; Close to Colleges & Landmark Industries
                      </li>
                  </ul>
              </div>

              <div className='py-4 px-3'>
                  <h4>NEIGHBORHOOD</h4>
                  <p>Discover the convenience of living in proximity to major landmarks and 
                    thriving environment:</p>
                  <ul>
                      <li>
                          &#8226; Golden Heart College 
                      </li>
                      <li>
                          &#8226; Nestle Water factory 
                      </li>
                      <li>
                          &#8226; Olam Agric
                      </li>
                      <li>
                          &#8226; Emzor Pharmaceutical
                      </li>
                      <li>
                          &#8226; International Brewery and many more!
                      </li>
                  </ul>
              </div>
            </div>

            <div className='shadow-1 px-3 py-2 flex flex-col gap-3 lg:max-w-[800px] lg:justify-center'>
                <h4>BANK DETAILS (Only for Instalment Payment)</h4>
                <p className='bg-[#af6ec2] py-1 px-3 text-white rounded-full text-[18px]'>Mercyland Estates Ltd</p>
                <p className='bg-[#af6ec2] py-1 px-3 text-white rounded-full text-[18px]'>Zenith Bank Plc &ndash; 1130071953</p>
                {/* <p className='bg-[#af6ec2] py-1 px-3 text-white rounded-full text-[18px]'>Guaranty Trust Bank &ndash; 0138007282</p> */}
            </div>

        <h3>INSPECTION</h3>
        <p>FREE inspection to the site is every week. It is important that you call the office phone lines to book ahead. By calling the office lines for proper scheduling.
          Please contact us today; 📞 08038949316, 09169880123, 09169880124, 09169880125
          Don't miss out on this incredible opportunity! Secure your plot now and be a part of MERCYLAND VICTORY ESTATE's prestigious community.
        </p>
      </div>
    </div>
  )
}

export default RealEstate
