import React from 'react'
import MercylandVideo from '../New assets/Mercyland_Delight_Estate.mp4'

function MercyLandDetails() {
  return (
    <div className='pt-[80px]'>
        <h3 className='bg-slate-200 text-[#af6ec2] shadow-1 fixed w-full py-2 px-4'>MERCYLAND DELIGHT ESTATE</h3>
      <div className='cursor-pointer pt-[100px] pb-[50px] px-4'>
            <div className='grid grid-col lg:grid-cols-2 gap-2 '>
                <video src={MercylandVideo} autoPlay='true' controls='controls' />
                <div className='lg:p-3 lg:shadow-1'>
                    <p>
                        Mercyland Delight Estate is an over 20 Acre exquisite residential development 
                        located in the heart of the New Lagos along the Ajah-Ibeju Lekki corridor. 
                        Purchasing a plot in our Mercyland Delight Estate takes away the pain of dealing 
                        with Land grabbers and brings the joys of making your dream home a reality. 
                        Investing in Mercyland Delight Estate guarantees a Return on Investment of 
                        between 50% to 100% within the next 24 months. Your investment is safe, secured 
                        and free from any form of Government acquisition.
                    </p>
                    <br/><br/>
                    <p>Land Texture/Status: 100% Dry <br/><br/>
                        Location: Maidan/Awoyaya(5 Minutes’ Drive from Mayfair Gardens, Awoyaya and 6 minutes to Lakowe 
                        Lakes Golf and Country Estate).</p>
                </div>
            </div>
            <div className='grid grid-col lg:grid-cols-2 mt-5 gap-4'>
                <div>
                    <h4>Estate Features</h4>
                    <ul>
                        <li>
                            &#8226;  Gated community
                        </li>
                        <li>
                            &#8226;  Paved road network
                        </li>
                        <li>
                            &#8226;  Green areas/landscaping
                        </li>
                        <li>
                            &#8226;  Electrification
                        </li>
                        <li>
                            &#8226;  Recreational centres/sporting arena
                        </li>
                        <li>
                            &#8226;  Free from land grabbers
                        </li>
                        <li>
                            &#8226;  Certificate of Occupancy
                        </li>
                        <li>
                            &#8226;  Instant Allocation
                        </li>
                    </ul>
                </div>

                <div>
                    <h4>Neighbourhood</h4>
                    <ul>
                        <li>
                            &#8226;  Mayfair Gardens
                        </li>
                        <li>
                            &#8226;  Lakowe Lakes Golf and Country Estate
                        </li>
                        <li>
                            &#8226;  Lagos Business School
                        </li>
                        <li>
                            &#8226;  Novare Mall(ShopRite)
                        </li>
                        <li>
                            &#8226;  Coscharis Motors
                        </li>
                        <li>
                            &#8226;  Green Springs School,Awoyaya
                        </li>
                        <li>
                            &#8226;  AutoNation
                        </li>
                    </ul>
                </div>
            </div>

            <div className='flex flex-col lg:flex-row gap-3 lg:justify-center'>
                <div className='shadow-1 px-3 py-2'>
                    <h4>Size/Plot</h4>
                    <p className='bg-[#af6ec2] py-1 px-3 text-white rounded-full text-[18px]'>600 sq.m</p>
                </div>
                <div className='shadow-1 px-3 py-2'>
                    <h4>Title</h4>
                    <p className='bg-[#af6ec2] py-1 px-3 text-white rounded-full text-[18px]'>C of O</p>
                </div>
                <div className='shadow-1 px-3 py-2'>
                    <h4>Outright Price</h4>
                    <p className='bg-[#af6ec2] py-1 px-3 text-white rounded-full text-[18px]'>N 35 million</p>
                </div>
                {/* <div className='shadow-1 px-3 py-2'>
                    <h4>Current Payment Plan</h4>
                    <p className='bg-[#af6ec2] py-1 px-3 text-white rounded-full text-[18px]'>Outright payment (₦22M)</p>
                </div> */}
                <div className='shadow-1 px-3 py-2'>
                    <p className='bg-[#af6ec2] py-1 px-3 text-white rounded-full text-[18px]'>No Installment</p>
                </div>
            </div>
            
            <div className='py-4'>
                <h4>Documents Received after payment</h4>
                <ul>
                    <li>
                        &#8226; Receipts of Purchase & Letter of Allocation
                    </li>
                    <li>
                        &#8226; Deed of Assignment
                    </li>
                    <li>
                        &#8226; Contract of Sale and Survey Plan
                    </li>
                </ul>
            </div>

            <div className='shadow-1 px-3 py-2 flex flex-col gap-3 lg:max-w-[800px] lg:justify-center'>
                <h4>Bank Details</h4>
                <p className='bg-[#af6ec2] py-1 px-3 text-white rounded-full text-[18px]'>Mercyland Estates Limited</p>
                <p className='bg-[#af6ec2] py-1 px-3 text-white rounded-full text-[18px]'>Zenith Bank Plc &ndash; 1011739921</p>
                <p className='bg-[#af6ec2] py-1 px-3 text-white rounded-full text-[18px]'>Guaranty Trust Bank &ndash; 0138007282</p>
            </div>
      </div>
    </div>
  )
}

export default MercyLandDetails
