import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image2 from '../New assets/Flyers/MERCYLAND delight Outer flyer.jpg'
import Image3 from '../New assets/Flyers/Farm estate outer flyer.jpg'
import '../styles/Mercyland.css'
import '../styles/MercyFarm.css'
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from 'react-router-dom'

function Mercyland() {

  const navigate = useNavigate();

  return (
    <div className='container grid gap-20  py-10 '>
      <div className='grid lg:grid-cols-2 gap-10'>
        <div className='image--text w-100'>
          <img className='image--div w-[100%] rounded-3xl' src={Image2} />
        </div>
        <div className='' >
          <h2 className='text-white'>Mercyland Delight Estate</h2>
          <p className=' text-white'>Mercyland Delight Estate is an over 20 acre exquisite residential 
            development located in the heart of the New Lagos along the Ajah-Ibeju Lekki corridor. 
            Purchasing a plot in our Mercyland Delight Estate takes away the pain of dealing with Land 
            grabbers and brings the joys of making your dream home a reality. Investing in Mercyland 
            Delight Estate guarantees a Return on Investment of between 50% to 100% within the next 24 
            months. Your investment is safe, secured and free from any form of Government acquisition.</p>
          <button onClick={() => navigate('/mercyland-details')} className='border-green-500 border-1 rounded-lg py-2 px-3 hover:bg-green-500 hover:text-white text-white text-bold duration-200'>More Info</button>
        </div>
      </div>


      <div className='grid lg:grid-cols-2 gap-10'>
        <div className='image2--div w-100'>
           <img className='w-[100%] rounded-3xl' src={Image3} />
        </div>
        
          
        {/*<div  className="farmImage max-w-[700px]" />*/}
        
        <div className=''>
            <h2 className='text-white'>Mercyland Back2Farm Scheme</h2>
            <p className='text-white'>
              Agricultural business can pay and feed 95% of the worlds' population at all times
              regardless of the hike in inflation. Owning a Farmland in Mercyland Farm Estate
              (Back2Farm Scheme) located in Shodolu/Adegesin/Eruwon via Ashipa under Obafemi-Owode
              LGA, Ogun State. It provides you a huge opportunity to meet the food needs of millions
              of Nigerians (both home & abroad) which in return give access to wealth creation from
              high returns on Investment...
            </p>
            <button onClick={() => navigate('/mercylandfarm-details')} className='border-green-500 border-1 rounded-lg py-2 px-3 hover:bg-green-500 hover:text-white text-white text-bold duration-200'>More Info</button>
        </div>
          
      </div>
    </div>
  )
}

export default Mercyland
