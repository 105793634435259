import React from 'react'
import LandImage from '../New assets/Flyers/MERCYLAND Farm Inner flyer.jpg'

function MercylandFarmdetails() {
  return (
    <div className='pt-[120px] '>
      <h2 className='text-center font-bold'>MERCYLAND BACK2FARM SCHEME</h2>
      <div>
        <div className='container grid grid-col lg:grid-cols-2 gap-4 pt-20 pb-10'>
          <div>
            <h4>PROPERTY DESCRIPTION</h4>
            <p className='text-[18px]'>
            Do you need a farmland? Or are you looking for a fertile land to invest in farming?
            MERCYLAND ESTATES LIMITED BACK2FARM SCHEME calls out to all farmers, intending farmers, Agriculturist and property investors to get fertile farm land with ease at an affordable price, located under Obafemi Owode L.G.A, close to RCCG Redemption City, Mowe, Ogun State.
            </p>

            <h5>LOCATION</h5>
            <p className='text-[18px]'>SHODOLU/ADEGESIN/ERUWON via Ashipa Town, Siun, off Abeokuta Expressway, Sagamu Interchange. About 25 minutes’ drive from RCCG Redemption Camp, Mowe, Ogun State.
            </p>
            <h5>TITLE</h5>
            <p>
              Perimeter Survey | Deed of Assignment | Ogun State Land Charting Information Certificate (LCIC) 
            </p>
            
          </div>
          <div className='shadow-1'>
          <img className='rounded-3xl w-[100%]' src={LandImage} />
          </div> 
        </div>
        <div className='mb-10'>
          <h4 className='text-center bg-green-500 rounded-3xl text-white w-[200px] mx-auto mt-3 py-2'>OUTRIGHT PRICE</h4>
          <p className='font-bold text-[25px] text-center'>N2,500,000m per Acre, N1,250,000m per ½ Acre</p>
        </div>

          <div className='bg-slate-100 text-center py-20'>
            <h1 className='text-center font-bold mb-4'>BENEFITS</h1>
            <div className='container bg-green-500 p-2 rounded-3xl text-white text-[25px] font-bold py-5 w-auto'>
              <p className=''>Be ready to start farming within 30 days</p>
              <p className=''>Deposit 50% of the land price</p>
              <p className=''>Instant land allocation</p>
              <p className=''>Spread the balance for 36 Months</p>
              <p className=''>Opened to interested Individuals, Groups and Cooperative Societies</p>
            </div>
          </div>
      </div>
      {/* <div className='text-center py-20'>
        <h1 className='text-center font-bold mb-4'>Terms & Conditions apply</h1>
        <div className='container bg-green-500 p-2 rounded-3xl text-white text-[25px] font-bold py-5 w-auto'>
          <h3 className='text-center'>OTHER FEES</h3>
          <p>
            Deed of Assignment - ₦200,000 per acre & ₦50,000 per plot<br/><br/>
            Survey Fees - ₦200,000 per acre & per plot<br/><br/>
            Survey in business name cost - ₦250,000 per acre<br/><br/>
            Development Fee - ₦250,000 per acre / ₦50,000 per plot (required at time of physical development)
          </p>
        </div>
      </div> */}

      <div>
        <div className='bg-slate-100'>
          <div className='container py-20 flex flex-col lg:flex-row gap-5 justify-center'>
            {/* <div className='bg-white shadow-1 p-5'>
              <h1 className='font-bold mb-4'>ESTATE FEATURES</h1>
              <div className='text-[20px]'>
                <p>Close to Lagos</p>
                <p>Good motorable road network</p> 
                <p>Close to colleges & industries</p>
                <p>Train station is about 11 minutes drive from the Estate junction</p>
              </div>
            </div> */}

            <div className=''>
              <h1 className='text-center font-bold mb-4'>NEIGHBOURHOOD</h1>
              <div className='container bg-green-500 text-center px-5 rounded-3xl text-white text-[25px] font-bold py-5 '>
                <p>Nestle Water Factory</p>
                <p>Emzor Pharmaceutical</p>
                <p>Olam Industries</p>
                <p>OPIC New Makun City</p>
                <p>Close to Lagos, and good motorable road</p>
                <p>Numerous Residential Estates</p>
                <p>Golden Heart College</p>
                <p>International Brewery and numerous industries around the Sagamu Interchange</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className='text-center py-20 font-bold'>
            <h4>LAND SIZE</h4>
            <p className='text-[25px] mb-5'>One Acre (6 Plots)  |   Half Acre (3 Plots)</p>

            {/* <h4>DOCUMENTS RECEIVED AFTER PAYMENT</h4>
            <p className='text-[25px]'>Receipts of Purchase & Letter of Allocation, 
              Deed of Assignment and Survey Plan</p> */}
          </div>
        </div>

        <div className='bg-slate-100'>
          <div className='container py-20 flex flex-col lg:flex-row gap-5 justify-center'>
            <div className='container bg-green-500 text-center px-5 rounded-3xl text-white text-[25px] font-bold py-5 '>
              <h1 className='text-center font-bold mb-4'>BANK DETAILS (Only for Instalment Payment)</h1>
              <p>Mercyland Estates Ltd <br/>
                1130071953 <br/>
                Zenith Bank
              </p>
            </div>

            <div className='bg-white shadow-1 p-5'>
              <h3 className='font-bold mb-4'>INSPECTION</h3>
              <p className='text-[20px]'>FREE inspection to the site is every week. It is important that you call the 
                office phone lines to book ahead. By calling the office lines for proper scheduling.<br/><br/>
              </p>
              <h3 className='font-bold mb-4'>FOR MORE ENQUIRIES</h3>
              <p className='text-[20px]'>CALL / WHATSAPP: 08038949316, 09169880123, 09169880124, 09169880125<br/><br/>
              </p>
            </div>
          </div>

          <div className='text-center font-bold text-[25px] py-10'>
            <p>Don’t be left out! Seize the opportunity to own a Farmland today!!!<br/>
                Back2Farm Scheme, FARM FOR GOOD, FARM FOR FOOD.
            </p>
          </div>
        </div>

        

      </div>
    </div>
  )
}

export default MercylandFarmdetails
